import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import Layout from "../components/Layout"
import { GrayText, H3 } from "../elements/shared/texts"
import { BsXCircle } from "react-icons/bs";

const Container = styled.div`
  ${tw`flex flex-col items-center my-32 h-full lg:w-5/12 md:w-5/12 py-24  sm:px-0 px-4 mx-auto`}
`

const CheckoutFailed = ( ) => {

  return (
    <Layout>
      <div className={"container mx-auto"}>
        <Container>
          <BsXCircle size={100}  style={{color: '#FF6288'}} />
          <H3 className={"pt-8"} style={{color: '#FF6288'}}>Checkout failed</H3>
          <GrayText>
            <p className={"pt-4"}>Failed to fulfill the order. Please verify your details and try again</p>
          </GrayText>
        </Container>
      </div>
    </Layout>
  )
}

export default CheckoutFailed